<template>
  <div class="px-2 pb-5">
    <UButton
      to="/compose"
      size="xl"
      class="rounded-full fixed right-2 bottom-24 p-4"
      icon="i-heroicons-pencil-square"
    />
    <UHorizontalNavigation
      :links="navLinks"
      :ui="mobileUi"
    />
  </div>
</template>

<script setup lang="ts">
const isPlaying = useIsAudioPlaying()

const route = useRoute()

const mobileUi = {
  container: 'justify-between w-full z-50',
  icon: {
    active: 'text-mexican-red',
    base: 'w-7 h-7 sm:w-8 sm:h-8'
  },
  active: 'after:hidden',
  label: 'hidden',
  size: 'text-sm',
  padding: 'py-3 px-4',
}

const navLinks = computed<{
  label: string
  to: string
  icon: string
  badge?: object
}[]>(() => {
  const links = [
    {
      label: 'Home',
      to: '/',
      icon: route.name === 'index' ? 'i-heroicons-home-solid' : 'i-heroicons-home',
    }, {
      label: 'Voices',
      to: '/voices',
      icon: route.name === 'voices' ? 'i-heroicons-megaphone-solid' : 'i-heroicons-megaphone',
    }, {
      label: 'Stories',
      to: '/stories',
      icon: route.name === 'stories' ? 'i-heroicons-newspaper-solid' : 'i-heroicons-newspaper',
    }, {
      label: 'Firehose',
      to: '/firehose',
      icon: route.name === 'firehose' ? 'i-heroicons-fire-solid' : 'i-heroicons-fire',
    }, {
      label: 'Listen',
      to: '/listen',
      icon: route.name === 'listen' ? 'i-heroicons-radio-solid' : 'i-heroicons-radio',
      badge: null,
    }, {
      label: 'Explore',
      to: '/explore',
      icon: route.name === 'explore' ? 'i-heroicons-magnifying-glass-solid' : 'i-heroicons-magnifying-glass',
    }, {
      label: 'Settings',
      to: '/settings',
      icon: route.name === 'settings' ? 'i-heroicons-cog-solid' : 'i-heroicons-cog',
    }
  ]

  if (isPlaying.value) {
    links[4].badge = {
        label: '⏵',
        variant: 'soft',
        color: 'mexican-red',
        size: 'xs',
        ui: {
          rounded: 'rounded-full',
          size: {
            xs: 'text-lg p-0 text-congress-blue',
          },
          variant: {
            soft: 'bg-transparent',
          }
        }
      }
  }

  return links
})

</script>
