<template>
  <div class="">
    <!-- Sidebar content goes here -->
    <UVerticalNavigation
      :links="topLinks"
      :ui="ui"
      class="hidden lg:block"
    />
    <UButton 
      to="/compose"
      label="Post"
      size="xl"
      class="hidden lg:flex w-full rounded-full shadow my-6 mt-4"
      icon="i-heroicons-pencil-square"
      block
    />
    <UVerticalNavigation
      v-if="['authenticated', 'loading'].includes(status)"
      :links="authLinks"
      :ui="ui"
    />
    <div
      class="grid grid-flow-col gap-2 lg:grid-flow-row"
      v-else
    >
      <UButton to="/log-in" label="Log in" variant="outline" size="lg" block />
      <UButton to="/sign-up" label="Sign up" size="lg" block />
    </div>
  </div>
</template>

<script setup lang="ts">
const { data: user, status } = useAuth()

const isPlaying = useIsAudioPlaying()

const route = useRoute()

const ui = {
  active: 'font-bold',
  base: 'before:rounded-full inline-flex mx-auto lg:flex',
  width: 'w-auto lg:w-full',
  inactive: 'font-normal',
  icon: {
    active: 'text-mexican-red',
    base: 'w-7 h-7'
  },
  label: 'text-gray-900 dark:text-gray-100',
  size: 'text-xl',
  avatar: {
    size: 'xs'
  },
  padding: 'py-3 px-4',
}

const authLinks = computed(() => {
  if (status.value !== 'authenticated') return []
  if (!user.value) return []
  return [
    {
      avatar: {
        src: user.value.avatar_urls[96],
        alt: user.value.name,
      },
      label: user.value.name,
      to: '/@' + user.value.slug,
      labelClass: 'text-sm text-ellipsis line-clamp-1',
    }
  ]
})

const topLinks = computed(() => {
  const links = [
    {
      label: 'Home',
      to: '/',
      icon: route.name === 'index' ? 'i-heroicons-home-solid' : 'i-heroicons-home',
    }, {
      label: 'Voices',
      to: '/voices',
      icon: route.name === 'voices' ? 'i-heroicons-megaphone-solid' : 'i-heroicons-megaphone',
    }, {
      label: 'Stories',
      to: '/stories',
      icon: route.name === 'stories' ? 'i-heroicons-newspaper-solid' : 'i-heroicons-newspaper',
    }, {
      label: 'Firehose',
      to: '/firehose',
      icon: route.name === 'firehose' ? 'i-heroicons-fire-solid' : 'i-heroicons-fire',
    }, {
      label: 'Listen',
      to: '/listen',
      icon: route.name === 'listen' ? 'i-heroicons-radio-solid' : 'i-heroicons-radio',
      badge: null,
    }, {
      label: 'Explore',
      to: '/explore',
      icon: route.name === 'explore' ? 'i-heroicons-magnifying-glass-solid' : 'i-heroicons-magnifying-glass',
    }, {
      label: 'Settings',
      to: '/settings',
      icon: route.name === 'settings' ? 'i-heroicons-cog-solid' : 'i-heroicons-cog',
    }
  ]

  if (isPlaying.value) {
    links[4].badge = {
        label: '►',
        variant: 'soft',
        color: 'mexican-red',
        size: 'xs',
        ui: {
          base: 'rounded-full',
          size: {
            xs: 'text-sm',
          },
          variant: {
            soft: 'bg-transparent',
          },
        }
      }
  }

  return links
})

</script>
